<template>
  <div>
      <div>
          <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2> Page жагсаалт </h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
            <router-link to="/add-page">
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
      <div class="panel tabled">
        <el-tabs v-model="activeTabStatus">
            <el-tab-pane label="Бүгд" name="all">
            </el-tab-pane>
        </el-tabs>
        <div class="pl20 pr20 table-filter mb10">
          <el-row :gutter="10" align="middle" type="flex">
          <el-col :span="10">
          <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
          <el-button slot="prepend" icon="el-icon-search"></el-button>
          </el-input>
          </el-col>
          <el-col :span="4">
                   <el-select v-model="selectStatus" @change="filterStatus" placeholder="Төлөв сонгох">
                    <el-option
                      v-for="(status, index) in statusSelect"
                      :key="index"
                      :label="status.label"
                      :value="status.value"
                      >
                    </el-option>
                  </el-select>
                </el-col>
          <el-col :span="10" class="text-right">
          <el-dropdown @command="handleSort">
              <el-button type="default">{{sortName}} <i class="el-icon-sort"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
      </el-col>
          </el-row>
        </div>
      </div>
     <el-table
     :cell-class-name="classChecker"
    :data="pageForm"
    v-loading="isLoading"
    style="width: 100%">
    <el-table-column
        label="Нэр"
        prop="name">
        <template slot-scope="scope">
        <span>{{scope.row.name}}</span>
        </template>
    </el-table-column>
    <el-table-column
        label="Slug"
        prop="slug">
    </el-table-column>
    <el-table-column label="Badge">
      <template slot-scope="scope">
        <div v-if="Object.prototype.hasOwnProperty.call(pageForm, 'badge_color') && scope.row.badge.badge_color !== null && scope.row.is_badge_show === true && scope.row.badge.badge_text_color !== null && scope.row.badge.badge_text !== ''" style="padding: 5px; display: inline-block; border-radius: 10px" :style="`background: ${scope.row.badge.badge_color}; color: ${scope.row.badge.badge_text_color}`">
          {{scope.row.badge.badge_text}}
        </div>
      </template>
    </el-table-column>
    <el-table-column
        label="menu_sort"
        prop="menu_sort">
    </el-table-column>
    <el-table-column label="Төлөв">
    <template slot-scope="scope">
    <el-tag v-if="scope.row.is_active" type="success">Идэвхтэй</el-tag>
    <el-tag v-else type="warning">Идэвхгүй</el-tag>
    </template>
    </el-table-column>
    <el-table-column
        label="Эхлэх өдөр"
        prop="start_date">
    </el-table-column>
    <el-table-column
        label="Хугацаа дууссах огноо"
        prop="expired_date">
    </el-table-column>
      <el-table-column
      label="">
      <template slot-scope="scope">
        <el-button
          size="mini"
          type="primary"
          @click="detailClick(scope.row)">Дэлгэрэнгүй</el-button>
      </template>
    </el-table-column>
      <el-table-column
      label="">
      <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger" icon="el-icon-delete"
          @click="approveDelete(scope.row)"></el-button>
      </template>
    </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount">
    </el-pagination>
    </div>
    <el-dialog
      :title="oneSlugData.name"
      :visible.sync="dialogVisible"
      width="30%">
      <span><strong>{{oneSlugData.name}}</strong> хуудсыг устгахдаа итгэлтэй байна уу?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Болих</el-button>
        <el-button type="danger" @click="deletePage">Устгах</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      oneSlugData: {},
      dialogVisible: false,
      sortName: 'Эрэмбэлэх',
      sort: '',
      selectStatus: '',
      field: '',
      activeTabStatus: 'all',
      search: '',
      pageForm: [],
      isLoading: false,
      pageSize: 20,
      pageFrom: 0,
      totalCount: 0,
      page: 1,
      currentPage: 1,
      statusSelect: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: 'active',
        label: 'Идэвхтэй'
      }, {
        value: 'inactive',
        label: 'Идэвхгүй'
      }],
      dropdownDataFilter: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },
  mounted () {
    var from = this.pageSize * (this.currentPage - 1)
    var size = this.pageSize
    if (
      this.$route.query.page &&
      this.$route.query.size &&
      this.$route.query.search &&
      this.$route.query.field &&
      this.$route.query.sort
    ) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.field = incomingField
      this.sort = incomingSort
      from = this.pageSize * (incomingPage - 1)
      size = incomingSize
    }
    this.getPage(from, size, this.search, this.field, this.sort)
  },

  methods: {
    approveDelete (data) {
      this.dialogVisible = true
      this.oneSlugData = data
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    deletePage () {
      services.delPage(this.oneSlugData.slug).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, 'success')
          this.getPage(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
          this.dialogVisible = false
        } else {
          this.alertReporter('Алдаа', response.message, 'error')
          this.dialogVisible = false
        }
      })
    },
    classChecker ({ row, column }) {
      if (column.property === 'name') {
        const val = row[column.property]
        if (val.length > 0) {
          return 'greenClass'
        } else {
          return 'redClass'
        }
      }
    },
    filterStatus () {
      this.currentPage = 1
      this.getPage(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
    },
    onSearch () {
      this.$router.push({
        name: 'pageList',
        query: {
          page: this.currentPage,
          size: this.pageSize,
          search: this.search,
          field: this.field,
          sort: this.sort
        }
      })
      this.getPage(
        this.pageSize * (this.currentPage - 1),
        this.pageSize,
        this.search,
        this.field,
        this.sort
      )
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({
        name: 'pageList',
        query: {
          page: item,
          size: this.pageSize,
          search: this.search,
          field: this.field,
          sort: this.sort
        }
      })
      this.getPage(
        this.pageSize * (this.currentPage - 1),
        this.pageSize,
        this.search,
        this.field,
        this.sort
      )
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({
        name: 'pageList',
        query: {
          page: item,
          size: this.pageSize,
          search: this.search,
          field: this.field,
          sort: this.sort
        }
      })
      this.getPage(
        this.pageSize * (this.currentPage - 1),
        this.pageSize,
        this.search,
        this.field,
        this.sort
      )
    },

    handleSort (data) {
      const filters = {
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'name_mon',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'name_mon',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.$router.push({
        name: 'pageList',
        query: {
          page: this.currentPage,
          size: this.pageSize,
          search: this.search,
          field: this.field,
          sort: this.sort
        }
      })
      this.getPage(
        this.pageSize * (this.currentPage - 1),
        this.pageSize,
        this.search,
        this.field,
        this.sort
      )
    },

    getPage (from, size, search, field, sort) {
      const query =
        '?search_text=' +
        search +
        '&from=' +
        from +
        '&size=' +
        size +
        '&field=' +
        field +
        '&sort=' +
        sort
      this.isLoading = true
      services.getPage(query).then((response) => {
        this.pageForm = response.datas
        this.totalCount = response.total
        this.isLoading = false
      })
    },

    detailClick (data) {
      this.$router.push({
        name: 'detailPage',
        params: {
          slug: data.slug
        }
      })
    }
  }
}
</script>
<style>
.el-table .greenClass {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  background: #231f20;
  padding: 5px;
  font-weight: bold;
  color: #a1a4ad;
}
.el-table .redClass {
  background-color: red;
  color: #a1a4ad;
}
.el-table .greenClass:hover {
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  background: #a1a4ad;
  font-weight: bold;
  color: #231f20;
}
.el-table .redClass:hover {
  background-color: #a1a4ad;
  color: red;
}
</style>
